import React from 'react';

const UploadingFile = () => (
  <div className="flex-column fill center">
    <span><i className="mdi mdi-spin mdi-refresh mdi-36px" /></span>
    <span>Uploading...</span>
  </div>
);

export default UploadingFile;
