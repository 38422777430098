import React from 'react';
import map from 'lodash/map';
import take from 'lodash/take';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { sOrNoS } from '../helpers/listHelpers';

const NUM_FILES_TO_PREVIEW = 2;

const SelectedFilePlaceholder = ({ selectedFiles }) => {
  if (isEmpty(selectedFiles)) {
    return null;
  }

  const numFilesSelected = size(selectedFiles);

  return (
    <div className="flex-column fill center">
      <span><i className="mdi mdi-36px mdi-file-outline" /></span>
      <h4>{numFilesSelected} File{sOrNoS(numFilesSelected)} Selected</h4>
      {
        map(take(selectedFiles, NUM_FILES_TO_PREVIEW), ({ name }) => {
          return (
            <div>{name}</div>
          );
        })
      }
      {numFilesSelected > NUM_FILES_TO_PREVIEW && (
        <div>and {numFilesSelected - NUM_FILES_TO_PREVIEW} more...</div>
      )}
    </div>
  );
};

export default SelectedFilePlaceholder;
