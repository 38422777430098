import React from 'react';

const UploadFilePlaceholder = () => {
  return (
    <div className="flex-column text-center">
      <span>Drop File Here</span>
      <span>or</span>
      <span className="text-primary">Browse</span>
    </div>
  );
};

export default UploadFilePlaceholder;
