import "modern-css-reset/dist/reset.css"
import {Helmet} from "react-helmet";
import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import appConfig from "../appConfig"
import { StringParam, useQueryParam } from "use-query-params"
import UploadGroupFileDropzone from "../components/UploadGroupFileDropzone"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Home = () => {
  const [{ isLoading, uploadGroupExists }, setUploadGroup] = useState({ isLoading: true, uploadGroupExists: false });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadGroupKey] = useQueryParam("uploadGroupKey", StringParam);

  async function uploadFiles(files) {
    const formData  = new FormData();

    for(const file of files) {
      formData.append('files', file);
    }

    try {
      setIsUploading(true);
      const startingToastId = toast.info('Starting Upload', {
        position: "top-right",
        autoClose: 30 * 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      const res = await fetch(`${appConfig.baseUrl}/api/uploads/${uploadGroupKey}`, {
        method: 'POST',
        body: formData
      });

      if(res.ok) {
        setTimeout(() => {
          setIsUploading(false);
          toast.dismiss(startingToastId);

          toast.success('Upload Complete', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 1000);
      } else {
        setTimeout(() => {
          setIsUploading(false);
          toast.dismiss(startingToastId);

          toast.error('Upload failed, please try again', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 1000);
      }
    } catch (e) {
      console.error(e);
      setIsUploading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${appConfig.baseUrl}/api/uploads/${uploadGroupKey}/exists`);
        if (res.ok) {
          setUploadGroup({ isLoading: false, uploadGroupExists: true })
        } else {
          setUploadGroup({ isLoading: false, uploadGroupExists: false })
        }
      }catch (e) {
        console.error(e)
      }
    })();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Send Files to Scott</title>
      </Helmet>
      {isLoading && (
        <div className="flex-column fill center">
          <h1>Loading...</h1>
        </div>
      )}
      {!isLoading && !uploadGroupExists && (
        <div className="flex-column fill center">
          <h1>No Upload Folder Found</h1>
        </div>
      )}
      {uploadGroupExists && (
        <>
          <h2>Upload Files to '{uploadGroupKey}'</h2>
          <UploadGroupFileDropzone
            uploadGroupKey={uploadGroupKey}
            isUploading={isUploading}
            setSelectedFiles={uploadFiles}
          />
        </>
      )}

      <ToastContainer />
    </Layout>
  )
};

export default Home
