import '../assets/scss/styles.css';

import React from 'react';

const Layout = ({ children }) => {
  return (
    <div className="app flex-column">
      {children}
    </div>
  );
};

export default Layout;
