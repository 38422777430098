import "modern-css-reset/dist/reset.css"

import React from "react"
import isEmpty from "lodash/isEmpty"
import size from "lodash/size"
import FileDropzone from "../components/FileDropzone"
import UploadingFile from "../components/UploadingFile"
import SelectedFilePlaceholder from "../components/SelectedFilePlaceholder"
import UploadFilePlaceholder from "../components/UploadFilePlaceholder"

const UploadGroupFileDropzone = ({ selectedFiles, isUploading, uploadGroupKey, ...props }) => {
  return (
    <FileDropzone
      onDrop={props.setSelectedFiles}
    >
      <div>
        {isUploading && (
          <UploadingFile />
        )}
        {!isUploading && (
          <>
            {size(selectedFiles) > 0 && (
              <SelectedFilePlaceholder
                selectedFiles={selectedFiles}
              />
            )}
            {isEmpty(selectedFiles) && (
              <UploadFilePlaceholder />
            )}
          </>
        )}
      </div>
    </FileDropzone>
  )
};

export default UploadGroupFileDropzone
