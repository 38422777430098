import isArray from 'lodash/isArray';
import size from 'lodash/size';

export const pluralCoalesce = (numOrArray, singular, plural) => {
  const num = isArray(numOrArray) ? size(numOrArray) : numOrArray || 0;

  return num === 1 ? singular : plural;
};

export const sOrNoS = (numOrArray) => {
  return pluralCoalesce(numOrArray, '', 's');
};
