import React  from 'react';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';

class FileDropzone extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  onDragEnter = () => {
    const { isHovered } = this.state;

    if (isHovered) {
      return;
    }

    this.setState({ isHovered: true });
  }

  onDragLeave = () => {
    const { isHovered } = this.state;

    if (!isHovered) {
      return;
    }

    this.setState({ isHovered: false });
  }

  onDrop = (...args) => {
    this.setState({ isHovered: false });

    if (this.props.onDrop) {
      this.props.onDrop.apply(null, args);
    }
  }

  render () {
    const { isHovered } = this.state;
    const { children, ...rest } = this.props;

    return (
      <>
        <Dropzone {...rest} onDrop={this.onDrop} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}>
          {({ getRootProps, getInputProps }) => (
            <div className={classnames('file-dropzone')} {...getRootProps()}>
              <input {...getInputProps()} />
              {!isHovered && (
                <>
                  {children}
                </>
              )}
              {isHovered && (
                <div className="dropzone-hover">
                  <span><i className="mdi mdi-36px mdi-file-outline" /></span>
                  <span>Drop File Here</span>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </>
    );
  }
}

export default FileDropzone;
